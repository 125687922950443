import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_ULEaqxbrm',
    userPoolWebClientId: '4v3ql2b2g86dm2utcqgtg9chg4',
    identityPoolId: 'ap-northeast-1:092cb6e6-865e-4857-8587-a17db67461b8',
  },
  Storage: {
     bucket: 'www.nanae.site',
     region: 'ap-northeast-1',
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
