
import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { Storage } from 'aws-amplify';

function App() {

  const [news,setNews] = useState([])
  const [topNews,setTopNews] = useState([])
  const [topnavClass,setTopnavClass] = useState('topnav')

  useEffect(() => {
    const f = async () => {

      const list = await Storage.list('', {
        customPrefix: { public: 'news/'}
      })

      const newsList = list.filter((item, i) => {
        const regexp = /^\d{8}_10sec|news_vol\d{1,}\.pdf$/
        return regexp.test(item.key);
      })

      let keys = newsList.filter((item, i) => {
        return newsList.length >= i + 6
      })

      keys = keys.map((item, i) => {
        return item.key
      })

      setNews(keys)

      let topKeys = newsList.filter((item, i) => {
        return newsList.length < i + 6
      })

      topKeys = topKeys.map((item, i) => {
        return item.key
      })

      setTopNews(topKeys.reverse())

    };

    f();

  },[])

  return (
    <div className="is-preload">
      <div className="topnav" id="myTopnav" style={{ top: 0 }}>
        <a href="#top" className="top_nav">
          TOP
        </a>
        <a href="#day_work" className="day_work_nav">
          日々の活動
        </a>
        <a href="#news" className="news_nav">
          市政ニュース
        </a>
        <a href="#first" className="first_nav">
          自己紹介
        </a>
        <a href="#policys1" className="policys1_nav policys2_nav policys3_nav">
          政策
        </a>
        <a href="#job" className="job_nav">
          市議の仕事とは？
        </a>
        <a href="#supporter" className="supporter_nav">
          後援会
        </a>
        <a href="#contact" className="contact_nav">
          連絡先
        </a>
        <a className="icon">
          <i className="fa fa-bars" />
        </a>
      </div>
      <div id="wrapper">
        <section id="top" className="intro">
          <header>
            <h1>
              <ruby>
                <rb>加賀</rb>
                <rp>（</rp>
                <rt style={{ fontSize: "0.5em" }}>かが</rt>
                <rp>）</rp>
              </ruby>
              ななえ
            </h1>
            <p>
              <strong>オフィシャルサイト</strong>
              <br />
              <br />
              <strong>富士見市議会議員</strong>
              <br />
              市民にいちばん身近な政治家
              <br />
              現職最年少 30歳
              <br />
              会派「草の根」
            </p>
          </header>
          <div className="content">
            <span className="image fill" data-position="center">
              <img src="images/top.jpg" alt="" />
            </span>
          </div>
        </section>
        <section id="election" className="policys">
          <header>
            <h2>
              2021年(令和3年)
              <br />
              富士見市議会議員選挙
              <br />
              について
            </h2>
          </header>
          <div className="content">
            <h2 style={{ textDecoration: "underline 5px orange" }}>
              加賀ななえ
              <br />
              2021年(令和3年) 富士見市議会議員一般選挙
              <br />
              当選のごあいさつ
            </h2>
            <p>
              この度行われた富士見市議会議員選挙で、3103票という票をいただき、3期目の当選をさせていただきました。
              <br />
              <br />
              寒い中、また足元も悪い中、投票所へ行って、名前を書いてくださって、本当にありがとうございました。
              <br />
              <br />
              先日、駅で私に期日前投票をしたとお声がけいただいた方に対して「がんばります」とお答えしたら、
              「選挙の今は力を入れずに頑張らなくていい。私は長い目で見て、より良い社会のために投票したのだから」と言われたことを思い出しました。
              <br />
              <br />
              私のために投票したのではなく私を通して実現できる社会のために投票してくださったのだと思います。
              <br />
              <br />
              今回の得票数の重さをきちんと受け止めて私は次の任期に全力で取り組むことをここにお約束致します。
              <br />
              <br />
              また、今回、私たちの会派草の根から立候補した大塚まさみ候補が落選という結果になってしまったことについては、
              会派全員の当選を果たすことを目標にしていたことからも、応援してくださった市民の方に申し訳ない気持ちでいっぱいです。
              <br />
              <br />
              今後は今回の結果を真摯に受け止めて、より一層、気を引き締めて活動して参る所存です。
            </p>
            <div style={{ textAlign: "right", marginBottom: 30 }}>
              <img src="images/sign.png" alt="" style={{ width: "8em" }} />
            </div>
            <iframe
              width="100%"
              height={315}
              src="https://www.youtube.com/embed/PY4hyOctdd8"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            />{" "}
            <img
              src="images/station.png"
              alt=""
              style={{ width: "100%", marginTop: "3%" }}
            />
            <img src="images/poster.png" alt="" style={{ width: "100%" }} />
          </div>
        </section>
        <section id="day_work">
          <header>
            <h2>日々の活動</h2>
          </header>
          <div className="content">
            <img
              src="images/katsudou.png"
              alt=""
              style={{ width: "6em", margin: "5% 40%" }}
            />
            <p style={{ textAlign: "center" }}>
              <strong>日々の活動をSNSで発信しています</strong>
            </p>
            <div className="sns">
              <a href="https://twitter.com/Nanaekaga" target="_blank">
                <img src="images/TwitterLogo.png" alt="" />
              </a>
              <a href="https://www.facebook.com/kaga.nanae" target="_blank">
                <img src="images/FBLogo.png" alt="" />
              </a>
              <a href="https://www.instagram.com/nanae.kaga/" target="_blank">
                <img src="images/InstagramLogo.png" alt="" />
              </a>
            </div>
          </div>
        </section>
        <section id="news" className="news">
          <header>
            <h2>市政ニュース</h2>
          </header>
          <div
            className="content"
            style={{
              textAlign: "center",
              display: "inline-flex",
              flexDirection: "column"
            }}
          >
            <img
              src="images/news.png"
              alt=""
              style={{ width: "6em", margin: "5% 40%" }}
            />


            {topNews.map((n, i) => {

              let ns = n.split('_')

              if(n){
                return(
                  <a
                    href={"news/" + n}
                    target="_blank"
                    className="button primary large"
                    key={i}
                  >
                    { ns[1] === 'news' ? '市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3) : '10秒で読める市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3)}
                    <br />
                    {'(' + ns[0].slice(0,4) + '/' + ns[0].slice(4,6) + '/' + ns[0].slice(6,8) + ' 公開)'}
                  </a>
                )
              }

            })}


            <details style={{ paddingTop: "2rem" }}>
              <summary style={{ textAlign: "center", outline: 0 }}>
                もっと見る (バックナンバー)
              </summary>
              <ul className="actions">

                {news.map((n, i) => {

                  let ns = n.split('_')

                  if(n){
                    return(
                      <li key={i}>
                        <a
                          href={"news/" + n}
                          target="_blank"
                          className="button primary large"
                        >
                          { ns[1] === 'news' ? '市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3) : '10秒で読める市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3)}
                          <br />
                          {'(' + ns[0].slice(0,4) + '/' + ns[0].slice(4,6) + '/' + ns[0].slice(6,8) + ' 公開)'}
                        </a>
                      </li>
                    )
                  }

                })}


              </ul>
            </details>
          </div>
        </section>
        <section id="first">
          <header>
            <h2>
              はじめまして、
              <br />
              加賀ななえです
            </h2>
          </header>
          <div className="content">
            <div className="gallery">
              <a href="images/manga.png" className="portrait">
                <img id="manga" src="images/manga.png" alt="" />
              </a>
            </div>
            <h2 style={{ margin: "60px 0px 20px" }}>プロフィール</h2>
            <p>
              1991年、ふじみ野生まれ。高校中退後、高等学校卒業程度認定試験(大検)を取得。
              <br />
              <br />
              2014年、明治大学政治経済学部政治学科を卒業後、針ヶ谷保育園、センター21障がい者自立支援施設に勤務。
              <br />
              <br />
              2016年7月に富士見市議会議員補欠選挙に25歳で初当選。（現在3期目）
            </p>
            <img
              src="images/gikai.jpg"
              alt=""
              style={{ width: "100%", borderRadius: 10 }}
            />
            <h2 style={{ margin: "60px 0px 20px" }}>趣味</h2>
            <img
              src="images/yomikikase.jpg"
              alt=""
              style={{ height: 200, borderRadius: 10 }}
            />
            <p>
              読みきかせ。
              鶴瀬小学校の学校応援団として読みきかせボランティアをしています。
            </p>
            <img
              src="images/inu.jpg"
              alt=""
              style={{ height: 200, borderRadius: 10 }}
            />
            <p>動物が大好き。</p>
            <img
              src="images/bt.jpg"
              alt=""
              style={{ height: 200, borderRadius: 10 }}
            />
            <p>バドテニス。 写真は、つるせ台体育館での練習風景です。</p>
          </div>
        </section>
        <section id="policys1" className="policys">
          <header>
            <h2>私が政治を志す「きっかけ」になったこと</h2>
          </header>
          <div className="content">
            <div className="sentence aspire">
              <h2>① 不登校・社会と断絶した疎外感</h2>
              <p>
                私は、小学校・中学校で不登校になった経験があります。
                <br />
                小学校５年生のときにいじめにあい、
                <br />
                しばらく学校を休んだことがきっかけで徐々に足が遠のいてしまったのです。
                <br />
                <br />
                気づけば、いわゆる不登校児となっていた私が感じたのは学校にいかないというだけで、自宅以外の居場所を完全になくし、社会と断絶した疎外感でした。
                <br />
                <br />
                今でこそ、フリースクールを単位として認めるなどという活動も徐々に行われるようになりましたが、まだ十分なサポートを得られる環境であるとは言えません。
                <br />
                <br />
                その後、アルバイトをしながら大検を取得。
                <br />
                学校に通っていなくとも、学びたいという気持ちは私のなかに強くあったのです。
                <br />
                <br />
                誰でも教育を受ける権利があるにもかかわらず、
                <br />
                その機会を、またそのあとの選択肢を奪われる子どもたちのいない社会を実現したい。
                <br />
                自身の経験から私は強くそう思うようになりました。
              </p>
            </div>
            <div className="sentence aspire">
              <h2>② 奨学金・重い返済</h2>
              <p>
                なんとかアルバイトと両立のなか、大学入学を果たすことができましたが、
                <br />
                十分な余裕があるとは言えないサラリーマン家庭で育った私は学べる喜びを得るとともに、
                <br />
                有利子型奨学金２５０万円を背負うことになりました。
                <br />
                <br />
                私はまだ恵まれた環境ですが　非正規雇用では返すことが難しいと感じました。
              </p>
            </div>
            <div className="sentence aspire">
              <h2>③ 待機児童・苦労する親御さんたち</h2>
              <p>
                保育園で働きながら、
                <br />
                何か所も保育園への申し込みに落ちながら
                <br />
                やっとのおもいでお子さんを預けられた親御さんたちを見ました。
                <br />
                <br />
                子どもを育てていくことへの不安を覚え、
                <br />
                誰もが本当に安心して出産、子育てできる環境が必要だと実感しました。
              </p>
            </div>
          </div>
        </section>
        <section id="policys2" className="policys">
          <header>
            <h2>政策に込める思い</h2>
          </header>
          <div className="content">
            <div className="sentence aspireall">
              <h2>
                すべての世代の人が
                <br />
                安心して生活できる富士見市へ
                <br />
                <br />
                教育・保育の充実は、
                <br />
                若い世代だけの為の政策では
                <br />
                ありません
              </h2>
              <p>
                若年人口が現在は微増している富士見市ですが、
                今後、少子高齢化は確実に進んでいきます。
                <br />
                <br />
                富士見市で安心して生活し、子育てをしてもらえる基盤を構築するかということは、
                高齢者世代を支える若い世代に安心して富士見市にとどまってもらうために、
                また、より多くの若い世代に富士見市で暮らしてもらうためにとても重要です。
                <br />
                <br />
                教育・保育の充実は、すべての世代の生活を守るために必要不可欠な、
                富士見市全体の遠くない未来のための政策です。
                <br />
                <br />
                本当にすべての世代が生活しやすい、 子育てがしやすい、
                誰もが学びやすい、 そんな富士見市を実現したいと考えています。
              </p>
            </div>
          </div>
        </section>
        <section id="policys3" className="policys">
          <header>
            <h2>
              加賀ななえが
              <br />
              取り組んでる政策
            </h2>
          </header>
          <div className="content">
            <div className="sentence policy" style={{ overflow: "hidden" }}>
              <img
                src="images/y.png"
                alt=""
                style={{ width: "6em", float: "right" }}
              />
              <h2 style={{ margin: 0, color: "#ea8e19", textTransform: "none" }}>
                不登校
              </h2>
              <h2>多様な学びの場の充実</h2>
              <p>
                <strong>すべての子供が学べる方法の選択肢を</strong>
                <br />
                <br />
                令和元年度、全国の不登校児童は18万人います。
                富士見市でも小学校33人、中学校84人が不登校状態にあります。
                <br />
                <br />
                不登校支援のあり方は平成28年に制定された教育機会確保法（正式名称は義務教育の段階における普通教育に相当する教育の機会の確保等に関する法律）
                の制定によって大きく変わっています。
                以前は不登校は問題行動であるとされ学校復帰を前提とする支援しか行われていませんでした。
                <br />
                <br />
                しかし、どれだけ学校復帰を促しても増加し続ける不登校や、夏休み明けの子どもの自殺が後を絶たないことなどの背景から、
                支援の方向性が多様な学びの場を認めるよう転換しています。
                <br />
                <br />
                教育機会確保法により自治体には多様な学びの場を確保する予算の努力義務が生じました。
                <br />
                <br />
                教育機会確保法に基づいて、
                令和元年10月25日付で文部科学省から「不登校児童生徒への支援の在り方について」という新たな通知が各自治体の教育委員会に出されたことを受け、
                令和元年12月議会の一般質問で新たな不登校支援のあり方を提案しました。
                <br />
                <br />
                その後、富士見市教育委員会では令和２年、「不登校児童が自宅でICT等を活用した場合でも、出席扱いにできるガイドライン」が策定されました。
                今後は、iPadやタブレット端末が全ての児童に配布されることを活かして、不登校児童向けのオンライン授業の早急な実施を訴えて参ります。
              </p>
            </div>
            <div className="sentence policy">
              <h2 style={{ margin: 0, color: "#ea8e19" }}>奨学金</h2>
              <h2>もらいやすく、かえせるように</h2>
              <p>
                <strong>
                  奨学金の拡充の第一歩として、入学準備金制度の拡充に取り組んでいます。
                </strong>
                <br />
                <br />
                高校や大学などに入学する際
                準備のためにかかるお金(教材費制服など)である入学準備金への支援拡充に取り組んでいます。
                現在の富士見市には、入学準備金
                上限70万円のうち利子の一部を富士見市が負担する制度があります。
                <br />
                <br />
                入学準備金利子補給制度を実施している他の自治体によっては上限200万円など富士見市の現在の上限額70万円よりも多く設定しているところもあり、現在の上限額を引き上げるように提案しました。
                <br />
                <br />
                富士見市で実施されている「入学準備金利子補給制度」の拡充を9月議会で働きかけ、「平成29年度より予算枠の拡充を検討する」との答弁を得ました。
                今後は制度の周知も含め、より利用しやすい制度づくりに向けて提言を行っていきます。
              </p>
            </div>
            <div className="sentence policy">
              <h2 style={{ margin: 0, color: "#ea8e19" }}>待機児童</h2>
              <h2>
                だれもが安心して、
                <br />
                子供を生み、
                <br />
                育てることのできる世の中に
              </h2>
              <p>
                <strong>待機児童0に向けて早急な対策に取り組みます。</strong>
                <br />
                <br />
                だれもが安心して、 子供を生み、育てることのできる世の中に
                待機児童0に向けて早急な対策に取り組みます。
                <br />
                <br />
                富士見市には現在、認可外保育園に入れず待機している児童、親御さんたちがいます。
                （令和2年度4月現在 19名）
                <br />
                <br />
                2016年から入園申し込みの時期、保護者と市の保育課の相談窓口の架け橋になるよう奮闘し、あらためて誰もが本当に安心して出産、子育てできる環境が必要だと実感しました。
                <br />
                <br />
                令和４年度において小規模保育施設を１施設開設する計画となっていますが、入園希望者は今後も増加が見込まれます
                <br />
                <br />
                今後は、政策面でより保育の充実を提言してまいります。
              </p>
            </div>
            <div className="sentence policy">
              <h2 style={{ margin: 0, color: "#ea8e19", textTransform: "none" }}>
                LGBTs
              </h2>
              <h2>多様な性のあり方が認められる社会に</h2>
              <p>
                <strong>同性パートナーシップ制度の導入</strong>
                <br />
                <br />
                富士見市でも多様な性のあり方が認められる社会の実現のため、同性パートナーシップ制度の導入に取り組んでいます。
                <br />
                <br />
                平成30年12月議会で、富士見市出身の当事者男性より、「富士見市におけるパートナーシップの公的認証と性的マイノリティーに関する諸問題への取組に関する陳情」が出され、
                全会一致で採択されました。
                <br />
                <br />
                その後、主に、令和元年6月議会と令和2年9月議会の一般質問などを通して、早急に同性パートナーシップ制度を導入するよう提案して参りました。
                <br />
                <br />
                令和2年9月議会で部長より、「令和３年は導入に向けた具体的な準備をする」という答弁をいただいました。今後の進捗を後押ししていきたいと考えています。
              </p>
            </div>
            <div className="sentence policy">
              <h2 style={{ margin: 0, color: "#ea8e19" }}>
                デマンド型交通の早期運転実施
              </h2>
              <h2>買い物や病院もあきらめないでいい</h2>
              <p>
                <strong>
                  障がい者や高齢者の足の確保のために、利用しやすい公共交通の実現に取り組んでいます。
                </strong>
                <br />
                <br />
                デマンド型交通とは、要求という意味の「デマンド」が表す通り、利用者の要求に対してできるだけ応える種類の公共交通です。
                <br />
                <br />
                予約など利用者のニーズに応じて柔軟な運行が行えるので、決まった時刻、決まった路線のバスなどと比較して、人口低密度地域やバスが走れないエリアに有効です。
                また障がい者や高齢者の足の確保としても有効といわれています。
                <br />
                <br />
                当選以来、デマンド型交通の必要性を訴えて参りましたが、令和元年６月、デマンドタクシーの利用が開始いたしました。
                <br />
                今後、利用者の声を伺いながら制度の改善について提案して参ります。
              </p>
            </div>
            <div className="sentence policy" style={{ overflow: "hidden" }}>
              <img
                src="images/ty.png"
                alt=""
                style={{ width: "6em", float: "right" }}
              />
              <h2 style={{ margin: 0, color: "#ea8e19" }}>
                災害時の情報提供ツールの充実
              </h2>
              <h2>一目でわかる災害情報をデータ放送で</h2>
              <p>
                <strong>
                  いざという時のために。
                  <br />
                  災害時の情報提供ツールの充実に取り組んでいます。
                </strong>
                <br />
                <br />
                2016年8月の台風9号の際、「防災無線がきこえず不安だった」「どこに避難したらいいかわからなかった」という声がきかれました。
                <br />
                <br />
                災害時の情報提供ツールを増やすことで、災害時に行政からの情報がきちんと行き届くように政策面から取り組みたいと思います。
                <br />
                <br />
                特に県内半数の自治体が行っているテレビ埼玉と契約すると、データ放送ボタンを押すと一目でどこに避難していいかがわかるようになります。
                <br />
                <br />
                こちらは、平成29年に実現しました。
                引き続き市民の声を伺いながら災害時の情報ツール充実について要望していきます。
              </p>
            </div>
          </div>
        </section>
        <section id="job" className="job">
          <header>
            <h2>市議会議員の仕事とは？</h2>
          </header>
          <div className="content">
            <div className="sentence overview">
              <h2>市議会議員の主な仕事内容</h2>
              市議会議員の最も基本的な仕事は市議会の本会議や委員会に出席して、
              <br />
              ・条例の制定・改廃
              <br />
              ・予算の決定
              <br />
              ・決算の認定
              <br />
              ・重要な契約の締結
              <br />
              ・財産の取得・処分等
              <br />
              について承認を行うことです。
              <br />
              <br />
              市議会議員は、市民にとって、
              <br />
              提出された議案とそれを実施する理由が本当に合理的であるか慎重に判断する必要があります。
            </div>
            <div className="sentence question">
              <h2>市議会議員の仕事の成果と一般質問について</h2>
              富士見市議会議員として、仕事の成果とは何かと考えたとき、
              <br />
              私には議決に関わること以外にも、非常に大事な成果と考えていることがあります。
              <br />
              <br />
              それが、一般質問を通した市民の方々の要望の市政への反映です。
              <br />
              一般質問とは、市政全般に関して議員から市長をはじめとする行政側に現状や見通しを質問する事です。
              <br />
              この一般質問を通して、議員は市民生活をより良いものにする政策や、市民の方々からお聞きした要望などを、
              行政側に伝えて検討してもらうことで、実際の政策実現に繋がります。
              <br />
              <br />
              富士見市では全議員に議会中にこの機会が与えられています。
              <br />
              私は、この貴重な機会を活かして、地域活動や駅頭でうかがった市民の課題を議会で問題提起し、
              より良い市政を実現することが、富士見市議会議員としての非常に重要な仕事の1つだと考えており、
              一般質問で自身が取り上げた内容の政策への反映を仕事の成果と重要な指標として捉えています。
              <br />
              <br />
              <h2>一般質問から政策へつながる例</h2>
              <div className="questionflow clearfix">
                <h3>市民の声</h3>
                <img src="images/q1.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  学校へ行けない子供へ、学校以外の多様な学びの場を提供してほしい
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h3>調査・研究</h3>
                <img src="images/q2.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  タウンミーティングでのヒアリング、勉強会への出席、先進事例の視察、その他資料での調査などを行って、一般質問の準備をする
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h3>一般質問</h3>
                <img src="images/q3.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  ・ホームスタディ制度の導入を要望！
                  <br />
                  ・タブレットを活用したオンライン授業の実施を要望！
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h3>行政側の答弁</h3>
                <img src="images/q4.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  「不登校児童が自宅でICT等を活用して学習した場合でも出席扱いとする」ガイドラインの策定を検討します。
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h3>政策の実現（成果）</h3>
                <img src="images/q5.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  不登校児童が自宅で学習を行った場合でも、学習指導要領に則っている場合は出席扱いにできるガイドラインができました！
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="supporter">
          <header>
            <h2>
              加賀ななえ後援会
              <br />
              「ななえ会」
              <br />
              ご入会はこちらから
            </h2>
          </header>
          <div className="content">
            <img
              src="images/kochira.png"
              alt=""
              style={{ width: "6em", margin: "5% 40%" }}
            />
            <p>
              ご入会いただいた方には、市政ニュースなどの刊行物や、
              タウンミーティングのご案内等をさせていただいております。
              <br />
              <br />
              お手すきの際にボランティアスタッフとしてお手伝いいただける方、
              お手伝いいただく時間は無くてもSNS上だけでも応援いただける方、
              富士見市の市政に少しでもご興味・ご意見のある方など、
              どなたでもご協力をお願いいたします。
              <br />
              <br />
              富士見市の市政を身近に感じていただきたいと思っておりますので、
              どうぞお気軽にご入会ください。
              <br />
              <br />
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://docs.google.com/forms/d/1AGhT07cOUSg--_9WLNt-jHAsHRPhYxqulkjEQdeNTxE/viewform"
                  className="button primary large"
                >
                  ご入会はフォームへ
                </a>
              </li>
            </ul>
            <p style={{ fontSize: "60%" }}>
              ※入会費・年会費等は一切かかりません
              <br />
              ※ご記入いただいた個人情報については、後援会活動以外には利用いたしません。また、厳重に管理・保護の上、その取扱につきましては、法令その他の規範を遵守し細心の注意を払っております。
            </p>
          </div>
        </section>
        <section id="contact">
          <header>
            <h2>連絡先</h2>
          </header>
          <div className="content">
            <p>
              <strong>
                富士見市に住んでいて困ったこと・解決したいことをお聞かせください
              </strong>
              <br />
              <br />
              富士見市政や加賀ななえの政策へのご意見、富士見市民の方のお困り事、加賀ななえへの励ましのお言葉等など、フリーメールアドレスでも構いませんので、どうぞお気軽にメールしてください。
            </p>
            <p>
              <a href="mailto:kagananae@gmail.com">
                <strong style={{ color: "#ea8e19" }}>kagananae@gmail.com</strong>
              </a>
            </p>
          </div>
          <footer>
            <ul className="items">
              <li>
                <h3>Email</h3>
                <a href="mailto:kagananae@gmail.com">kagananae@gmail.com</a>
              </li>
              <li>
                <h3>Phone</h3>
                <a href="tel:08040517711">(080) 4051-7711</a>
              </li>
              <li>
                <h3>Address</h3>
                <span>
                  〒354-0024
                  <br />
                  富士見市鶴瀬東 2-18-14
                  <br />
                  クリオ鶴瀬壱番館 306
                </span>
              </li>
              <li>
                <h3>SNS</h3>
                <ul className="icons">
                  <li>
                    <a
                      href="https://twitter.com/Nanaekaga"
                      target="_blank"
                      className="icon fa-twitter"
                    >
                      <span className="label">Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/kaga.nanae"
                      target="_blank"
                      className="icon fa-facebook"
                    >
                      <span className="label">Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/nanae.kaga/"
                      target="_blank"
                      className="icon fa-instagram"
                    >
                      <span className="label">Instagram</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </footer>
        </section>
        <div className="copyright">© ななえ会. All rights reserved.</div>
      </div>

    </div>

  );
}

export default App;
